import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';

import { StylesProvider, insertCss } from '@rexlabs/styling';
import { createTokenFn } from '@rexlabs/element-styles';
import { TOKENS } from '@rexlabs/theme-luna';
import { merge } from 'lodash/fp';

import setWebpackPath from 'utils/set-webpack-async-modules-public-path';
setWebpackPath();

window.__Components = window.__Components || {};
window.__TwigReact = window.__TwigReact || {};

insertCss(`
    html {
      font-size: 62.5%;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }  
`);

export const SITELOFT_TOKENS = merge(TOKENS, {
  // Add global token overrides here
  color: {
    border: {
      input: {
        idle: '#e5e5e5',
        active: '#bbbbbb',
        hover: '#bbbbbb'
      },
      radius: {
        small: '0.3rem'
      }
    }
  },
  border: {
    radius: {
      small: '0.3rem'
    }
  },
  shadow: {
    focus: '0 0 0 0.3rem hsla(0, 0%, 73%, 33%)'
  },
  textInput: {
    border: {
      radius: ({ token }) => token('border.radius.small'),
      width: 2,
      borderColor: ({ token }) => token('color.border.input.idle')
    }
  },
  field: {
    maxWidth: '100%'
  },
  button: {
    boxShadow: 'none',
    border: {
      radius: ({ token }) => token('border.radius.small')
    }
  }
});

export const token = createTokenFn(SITELOFT_TOKENS);

function r(elementId, props = {}, path) {
  const rootElement = window.document.getElementById(elementId);
  if (!rootElement) {
    console.error(`Element "#${elementId}" not found!`);
  }

  // TODO: consider requiring the template to narrow down the amount of bundles being made (to improve build time)
  const Component = lazy(() =>
    import(`./personas/${path}.tsx`).catch(() =>
      import(`./personas/${path}.js`)
    )
  );

  function Root() {
    return (
      // TODO: add component loading states
      <StylesProvider tokens={SITELOFT_TOKENS}>
        <Suspense fallback={null}>
          <Component {...props} />
        </Suspense>
      </StylesProvider>
    );
  }

  render(<Root />, rootElement);
}

window.__TwigReact.render = r;
